<template>
  <div class="auth-v1 px-1">
    <div class="auth-inner py-1">
      <!-- Card principal -->
      <b-card style="text-align: center">
        <banca-logo style="width:69%" />
        <b-card-title class="text-center">
          Welcome to Bancannabis, {{ userData.name }}!
        </b-card-title>
        <b-card-text class="text-center">
          Your account has been successfully created. You now have full access to the digital cannabis market, where you can explore opportunities, invest, and engage in a cutting-edge ecosystem.
        </b-card-text>

        <!-- <b-img
          :src="require('@/assets/images/welcome-banner.png')"
          class="product-img my-3"
          fluid
          alt="Bienvenida Bancannabis"
        /> -->
      </b-card>

      <!-- Sección de la billetera -->
      <b-card class="mt-2">

        <b-card-title class="text-center">
          Your Digital Identity Is Ready!
        </b-card-title>
        <b-card-text class="text-center">
          Your linked wallet is: <strong>{{ userData.wallet }}</strong>
          <br>
          Use this address to deposit funds, sign contracts, and tokenize projects within the Bancannabis crypto-cannabis ecosystem.
        </b-card-text>

        <!-- <b-img
          :src="require('@/assets/images/wallet-info.png')"
          class="product-img my-3"
          fluid
          alt="Información de billetera"
        /> -->
      </b-card>

      <!-- Botón para continuar -->
      <b-button
        variant="success"
        block
        @click="goToHome"
      >
        Siguiente
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BButton,
} from 'bootstrap-vue'
import bancaLogo from '@core/layouts/components/HeaderLoginNft.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    // BImg,
    bancaLogo,
    BButton,
  },
  data() {
    // debugger
    return {
      userData: {
        name: (this.$route.params) ? this.$route.params.userData[0].email : 'Usuario',
        wallet: (this.$route.params) ? this.$route.params.userData[0].wallet : 'No Wallet Found',
      },
    }
  },
  methods: {
    goToHome() {
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper {
  background-color: #f5f5f5;
}

.auth-inner {
  max-width: 700px;
  margin: auto;
}

b-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

b-card-title {
  font-size: 1.5rem;
  color: #0b7d39;
}

b-card-text {
  font-size: 1rem;
  color: #333;
}

b-button {
  margin-top: 20px;
}
</style>
